import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { FormikProvider, useFormik } from 'formik';

// material
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

// @types
import { Organization } from 'services/ApiService';

import useOrganization from '../../../hooks/useOrganization';
import { PLAN_TYPE } from '../subscriptions/PLAN_TYPE';

type OrganizationCreateDialogProps = {
  open: boolean;
  setOpen: (values: boolean) => void;
};
export default function OrganizationCreateDialog({
  open = false,
  setOpen
}: OrganizationCreateDialogProps) {
  const { createOrganization, subscriptionPlan } = useOrganization();

  const { enqueueSnackbar } = useSnackbar();

  const UpdateOrganizationSchema = Yup.object().shape({
    name: Yup.string().required('Organization Name is required')
  });

  const formik = useFormik<Organization>({
    enableReinitialize: true,
    initialValues: {
      name: ''
    },
    validationSchema: UpdateOrganizationSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      setSubmitting(true);
      try {
        if (subscriptionPlan.planType === PLAN_TYPE.STARTER) {
          enqueueSnackbar('You already have a free organization. Upgrade it to create a new one.', {
            variant: 'error'
          });
        } else {
          await createOrganization(values);
          enqueueSnackbar('New Organization Created', { variant: 'success' });
        }

        setSubmitting(false);
        setOpen(false);
      } catch (error: any) {
        setSubmitting(false);
      }
    }
  });

  const { handleSubmit, getFieldProps, touched, errors, isSubmitting } = formik;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormikProvider value={formik}>
      {/* <Form autoComplete="off" noValidate onSubmit={handleSubmit}> */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
        <DialogTitle>Create a Free Organization</DialogTitle>
        <DialogContent sx={{ mb: 0 }}>
          <DialogContentText>
            {subscriptionPlan.planType === PLAN_TYPE.STARTER &&
              `Note: free plans can't create multiple organizations`}
          </DialogContentText>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Organization Name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={isSubmitting} onClick={() => handleSubmit()}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
      {/* </Form> */}
    </FormikProvider>
  );
}
