import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import creditCardFill from '@iconify/icons-eva/credit-card-fill';
import plusFill from '@iconify/icons-eva/plus-fill';

import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Stack,
  Box,
  Divider,
  MenuItem,
  Typography,
  ButtonBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import MenuPopover from 'components/MenuPopover';
import useOrganization from '../../../hooks/useOrganization';
import { styled } from '@mui/material/styles';
import OrganizationCreateDialog from '../../../sections/_dashboard/Organization/OrganizationCreateDialog';
import OrganizationAvatar from 'components/OrganizationAvatar';
import { APPBAR_MOBILE, APPBAR_DESKTOP } from '..';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const OrganizationButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  textAlign: 'right',
  borderRadius: theme.shape.borderRadiusSm,
  padding: theme.spacing(0.5, 1),
  height: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    height: APPBAR_DESKTOP
  }
}));

export default function OrganizationPopover() {
  const anchorRef = useRef(null);
  const {
    selectedOrganization,
    subscriptionPlan,
    usersOrganizationmembership,
    setSelectedOrganizationId
  } = useOrganization();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [newOrgDialogOpen, setNewOrgDialogOpen] = useState(false);

  let ORG_OPTIONS = [
    {
      label: 'Subscriptions',
      icon: creditCardFill,
      linkTo: PATH_DASHBOARD.subscriptions.root
    },
    {
      label: 'Organization Settings',
      icon: settings2Fill,
      linkTo: PATH_DASHBOARD.organization.root
    }
  ];

  return (
    <>
      <OrganizationButton ref={anchorRef} onClick={handleOpen}>
        {!!selectedOrganization && (
          <Stack>
            <Typography variant="subtitle2" noWrap>
              {selectedOrganization.name}
            </Typography>
            <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
              {subscriptionPlan.name}
            </Typography>
          </Stack>
        )}
      </OrganizationButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 250 }}
      >
        {!!selectedOrganization && (
          <>
            <Box sx={{ my: 1.5, px: 2.5 }}>
              <Typography variant="subtitle1" noWrap>
                {selectedOrganization.name}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                {subscriptionPlan.name}
              </Typography>
            </Box>

            <Divider sx={{ my: 1 }} />

            <MenuItem
              onClick={() => {
                handleClose();
                setNewOrgDialogOpen(true);
              }}
              sx={{ typography: 'body2', py: 1, px: 2.5 }}
            >
              <Box
                component={Icon}
                icon={plusFill}
                sx={{
                  mr: 2,
                  width: 24,
                  height: 24
                }}
              />
              New Organization
            </MenuItem>

            {ORG_OPTIONS.map((option) => (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24
                  }}
                />

                {option.label}
              </MenuItem>
            ))}

            <Divider sx={{ my: 1 }} />

            <List sx={{ pt: 0 }}>
              {(usersOrganizationmembership || []).map((om) => (
                <ListItem
                  button
                  onClick={() => {
                    handleClose();
                    setSelectedOrganizationId(om.organizationId as number);
                  }}
                  key={om.organizationId || 0}
                >
                  <ListItemAvatar>
                    <OrganizationAvatar organization={om.organization} />
                  </ListItemAvatar>
                  <ListItemText primary={om.organization?.name} />
                </ListItem>
              ))}
            </List>
          </>
        )}
      </MenuPopover>

      <OrganizationCreateDialog open={newOrgDialogOpen} setOpen={setNewOrgDialogOpen} />
    </>
  );
}
