import { useContext } from 'react';
import { OrganizationContext } from '../contexts/OrganizationContext';

// ----------------------------------------------------------------------

const useOrganization = () => {
  const context = useContext(OrganizationContext);

  if (!context) throw new Error('Organization context must be use inside OrganizationProvider');

  return context;
};

export default useOrganization;
