import axios from 'axios';
import { isValid, parseJSON } from 'date-fns/esm';

// We are using axios, because fetch cant process or handle dates properly when using interfaces
// ----------------------------------------------------------------------

// Fix for axios parsing Json and then NSwag client failing because its already parsed.
// https://github.com/RicoSuter/NSwag/issues/3294#issuecomment-776944625
// transformResponse: []  is same as:
// options.transformResponse = (data: any) => data;
const axiosInstance = axios.create();

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

// Handle Dates to support the nswag setting "Date Time Type", gone with this option instead of the reviver, because you need to add it for every client.
// This is global.
export function handleDates(obj: any | Array<object>) {
  if (obj === null || obj === undefined || typeof obj !== 'object') return;

  if (obj instanceof Array) {
    // For each item in the array, handle dates.
    obj.forEach((val, key) => handleDates(obj[key]));
  } else {
    // Everything not an object has been returned.
    // Assuming this 'obj' is now an object.
    for (const key of Object.keys(obj)) {
      const value = obj[key];
      if (!!value) {
        if (typeof value === 'string') {
          const parsedDate = parseJSON(value);
          const isValidDate = isValid(parsedDate);
          if (isValidDate) {
            obj[key] = parsedDate;
          }
        }
        if (typeof value === 'object') {
          handleDates(value);
        }
      }
    }
  }
}
// If its an array, recursively convert

axiosInstance.interceptors.response.use((originalResponse) => {
  handleDates(originalResponse.data);
  return originalResponse;
});

export default axiosInstance;
