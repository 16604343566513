//
import { MAvatar } from 'components/@material-extend';
import { MAvatarProps } from 'components/@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import { Organization } from '../services/ApiService';

// ----------------------------------------------------------------------

interface OrganizationAvatarProps extends MAvatarProps {
  organization?: Organization;
}

export default function OrganizationAvatar({ organization, ...other }: OrganizationAvatarProps) {
  const display = organization?.name || 'O';

  return (
    <MAvatar
      // src={organization?.photoUrl || ''}
      alt={display}
      color={createAvatar(display).color}
      {...other}
      sx={{ borderRadius: 1 }}
    >
      {createAvatar(display).name}
    </MAvatar>
  );
}
