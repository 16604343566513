import { useQuery, useMutation } from 'react-query';
import { User, UsersApiClient } from '../services/ApiService';

import { complishareApiUrl } from 'config';
import axiosInstance from '../utils/axios';
const apiClient = new UsersApiClient(complishareApiUrl, axiosInstance);
export const queryKey = 'user';

export const useGetCurrentUser = (enabled: boolean) => {
  return useQuery<User, Error>(
    [queryKey],
    async () => {
      return apiClient.usersGetCurrent();
    },
    {
      enabled: enabled
    }
  );
};

export const useCreateUser = () => {
  return useMutation<User, Error, User>((user: User) => apiClient.usersPost(user));
};

export const useUpdateUser = () => {
  return useMutation<void, Error, User>((user: User) => {
    if (!!user?.userId) {
      return apiClient.usersPut(user.userId, user);
    } else {
      throw new Error('User Id must not be null');
    }
  });
};
