import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { useSnackbar } from 'notistack';

export default function ReactQueryDataProvider({ children }: any) {
  const { enqueueSnackbar } = useSnackbar();

  var queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (error: any, variables: unknown, context: unknown | undefined) => {
          // 🎉 only show error toasts if we already have data in the cache
          // which indicates a failed background update
          if (error !== undefined) {
            enqueueSnackbar(`Something went wrong: ${error?.error?.message}`, { variant: 'error' });
          }
        }
      }
    },
    // Use query cache for these so it doest show multiple times for example when the query is invalidated.
    queryCache: new QueryCache({
      onError: (error: any, query) => {
        // 🎉 only show error toasts if we already have data in the cache
        // which indicates a failed background update
        if (query.state.data !== undefined) {
          enqueueSnackbar(`Something went wrong: ${error?.error?.message}`, { variant: 'error' });
        }
      }
    })
  });

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}
