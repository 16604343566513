import ThemeConfig from '../theme';
import NotistackProvider from './NotistackProvider';
import ThemePrimaryColor from './ThemePrimaryColor';

export default function ThemeAndStylesProviders({ children }: any) {
  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          {/* eslint-disable-next-line prettier/prettier */}
          {children}
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
