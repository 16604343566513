import { useMutation } from 'react-query';
import { RegisterApiClient, User } from '../services/ApiService';

import { complishareApiUrl } from 'config';
import axiosInstance from '../utils/axios';
const apiClient = new RegisterApiClient(complishareApiUrl, axiosInstance);

export const useRegisterUser = () => {
  return useMutation<User, Error, User>((user: User) => apiClient.register(user));
};
