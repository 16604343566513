import mixpanel from 'mixpanel-browser';
import { mixpanelProjectToken } from './config';

let isNotProduction = process.env.NODE_ENV !== 'production';

if (!!mixpanelProjectToken) {
  mixpanel.init(mixpanelProjectToken, { debug: isNotProduction });
} else {
  // dev mixpanel id
  mixpanel.init('5215d2e76cf8bc5c5032fc2649029c5b', { debug: true });
  console.log('Failed to enable mixpanel as the project token was undefined');
}
