/* eslint-disable import/no-duplicates */
import { createContext, ReactNode, useEffect, useState } from 'react';

import { getApps, initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  TwitterAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
  User as FireBaseUser
} from 'firebase/auth';

// @types
import { FirebaseApp } from '@firebase/app';
import mixpanel from 'mixpanel-browser';

import { FirebaseContextType, AuthUser } from '../@types/authentication';

import { useGetCurrentUser, useUpdateUser } from 'api/Users';
import { useRegisterUser } from 'api/Register';
import { User } from '../services/ApiService';
//
import { firebaseConfig } from '../config';

// ----------------------------------------------------------------------

let firebaseApp: FirebaseApp;
if (getApps().length < 1) {
  firebaseApp = initializeApp(firebaseConfig);
}

const AuthContext = createContext<FirebaseContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [isCreatingUser, setCreatingUser] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);
  const [fireBaseUser, setFireBaseUser] = useState<AuthUser | null>(null);

  const { data: profile, isLoading: userIsLoading } = useGetCurrentUser(
    // For race condition where it was querying for profile data, when it was still creating user
    !isCreatingUser && !!isAuthenticated // isCreatingUser must be false for the api to be enabled.
  );

  // User Data
  const mutateRegisterUser = useRegisterUser();
  const mutateUpdateUser = useUpdateUser();

  useEffect(() => {
    onAuthStateChanged(getAuth(firebaseApp), async (authUsr: AuthUser) => {
      if (authUsr) {
        setFireBaseUser(authUsr);
        setIsAuthenticated(true);
        mixpanel.identify(authUsr?.uid);
        mixpanel.people.set({
          $email: authUsr.email
        });
      } else {
        setIsAuthenticated(false);
        setFireBaseUser(null);
        mixpanel.reset();
      }
    });
  }, []);

  // Based on loadded data determine initilized or not.
  useEffect(() => {
    // Show loading screen until we know that the user has been authenticated or not.
    // And the user has been loaded.
    if (isAuthenticated !== null) {
      // Only initilised if auth is loaded and our data from our db exists.
      // This solves the race condition on creating the user
      if (!isAuthenticated || (isAuthenticated && fireBaseUser && profile)) {
        setIsInitialized(true);
      } else {
        setIsInitialized(false);
      }
    }
  }, [isAuthenticated, fireBaseUser, profile, userIsLoading]);

  const login = async (email: string, password: string) => {
    const userCreds = await signInWithEmailAndPassword(getAuth(firebaseApp), email, password);
    mixpanel.track('Successful login', { loginType: 'emailPassword' });
    return userCreds;
  };

  const loginWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const userCreds = signInWithPopup(getAuth(firebaseApp), provider);
    mixpanel.track('Successful login', { loginType: 'google' });
    return userCreds;
  };

  const loginWithFaceBook = () => {
    const provider = new FacebookAuthProvider();
    return signInWithPopup(getAuth(firebaseApp), provider);
  };

  const loginWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    return signInWithPopup(getAuth(firebaseApp), provider);
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    // To handle race condition when user is authenticated after firebase user created
    // and then the UI querys data before our user create is completed.
    setCreatingUser(true);
    const res = await createUserWithEmailAndPassword(getAuth(firebaseApp), email, password);
    mixpanel.track('Sign Up', { email: email });
    try {
      const userModel: User = {
        firebaseId: res.user?.uid,
        emailAddress: email,
        displayName: `${firstName} ${lastName}`,
        firstName: firstName,
        surname: lastName
      };

      await mutateRegisterUser.mutateAsync(userModel);
      mixpanel.alias(res.user?.uid);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setCreatingUser(false);
    }
  };

  const updateProfile = async (values: User) => {
    await mutateUpdateUser.mutateAsync(values);

    mixpanel.people.set({
      $email: values.emailAddress,
      $first_name: values.firstName,
      $last_name: values.surname,
      $name: values.displayName,
      $phone: values.phoneNumber
    });
    return;
  };

  const logout = async () => {
    await signOut(getAuth(firebaseApp));
  };

  const reauthenticate = async (user: FireBaseUser, currentPassword: string) => {
    if (user && user?.email != null) {
      var cred = EmailAuthProvider.credential(user?.email, currentPassword);
      return reauthenticateWithCredential(user, cred);
    } else {
      throw Error('User not found or email null when authenticating');
    }
  };

  const resetPassword = async (email: string) => {
    await sendPasswordResetEmail(getAuth(firebaseApp), email);
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    const user = await getAuth(firebaseApp).currentUser;
    if (user && user?.email != null) {
      await reauthenticate(user, currentPassword);
      await updatePassword(user, newPassword);
    } else {
      throw Error('User not found or email null');
    }
  };

  const getIdToken = async () => {
    return getAuth(firebaseApp).currentUser?.getIdToken();
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isInitialized,
        method: 'firebase',
        authUser: fireBaseUser,
        user: {
          ...profile,
          userId: profile?.userId,
          // Override firebase data on top of profile.
          firebaseId: fireBaseUser?.uid,
          emailAddress: fireBaseUser?.email,
          photoUrl: fireBaseUser?.photoURL || profile?.photoUrl,
          displayName: fireBaseUser?.displayName || `${profile?.firstName}  ${profile?.surname}`,
          firstName: fireBaseUser?.firstName || profile?.firstName,
          surname: fireBaseUser?.lastName || profile?.surname,
          phoneNumber: fireBaseUser?.phoneNumber || profile?.phoneNumber || ''
        },
        userIsLoading,
        login,
        register,
        loginWithGoogle,
        loginWithFaceBook,
        loginWithTwitter,
        logout,
        resetPassword,
        changePassword,
        getIdToken,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
