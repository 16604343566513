import { Icon } from '@iconify/react';
import menuFill from '@iconify/icons-eva/menu-fill';
import flashFill from '@iconify/icons-eva/flash-fill';

import { NavLink as RouterLink } from 'react-router-dom';

// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Divider, Button } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from 'components/@material-extend';
import AccountPopover from './nav/AccountPopover';
import OrganizationPopover from './nav/OrganizationPopover';
import { DRAWER_WIDTH, COLLAPSE_WIDTH } from 'components/settings';
import { PATH_DASHBOARD } from '../../routes/paths';
import useOrganization from '../../hooks/useOrganization';
import { PLAN_TYPE } from '../../sections/_dashboard/subscriptions/PLAN_TYPE';
import { APPBAR_MOBILE, APPBAR_DESKTOP } from '.';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  borderTop: '1px solid #ececec',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  padding: '0 5px',
  borderBottom: '1px solid #f1f1f1',
  boxShadow: '1px 2px 4px 0 rgb(0 0 0 / 3%)',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 0, 0, 5)
  }
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar: VoidFunction;
};

export default function DashboardNavbar({ onOpenSidebar }: DashboardNavbarProps) {
  const { isCollapse } = useCollapseDrawer();

  const { subscriptionPlan } = useOrganization();

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="mdUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menuFill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5 }}>
          {subscriptionPlan.planType === PLAN_TYPE.STARTER && (
            <Button
              color="secondary"
              variant="contained"
              size="small"
              component={RouterLink}
              sx={{ mx: 2, boxShadow: 'none', fontWeight: 'normal' }}
              to={PATH_DASHBOARD.subscriptions.root}
              startIcon={<Icon icon={flashFill} width={15} height={15} />}
            >
              Upgrade to Pro
            </Button>
          )}
          <Divider variant="middle" flexItem orientation="vertical" />
          <OrganizationPopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
