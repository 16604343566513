// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function UploadIllustration({ ...other }: BoxProps) {
  return (
    <Box {...other} component="img" src={`/static/illustrations/illustration_upload.svg`}></Box>
  );
}
