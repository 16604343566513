// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from 'components/@material-extend';
import { MAvatarProps } from 'components/@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();

  const display = user?.displayName || user?.emailAddress || '';

  return (
    <MAvatar
      src={user?.photoUrl || ''}
      alt={display}
      color={user?.photoUrl ? 'default' : createAvatar(display).color}
      {...other}
    >
      {createAvatar(display).name}
    </MAvatar>
  );
}
