import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from 'components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen progressBarOnly={true} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/upload-request" replace /> },
        {
          path: 'upload-requirement',
          children: [
            { element: <Navigate to="/upload-requirement/list" replace /> },
            { path: 'list', element: <UploadRequirementTemplateList /> },
            { path: 'new', element: <UploadRequirementTemplateCreate /> },
            { path: ':id/new', element: <UploadRequirementTemplateCreate /> },
            { path: ':id/edit', element: <UploadRequirementTemplateCreate /> }
          ]
        },
        {
          path: 'receive',
          children: [
            { element: <Navigate to="/receive/new" replace /> },
            { path: 'new', element: <UploadRequestCreate /> },
            { path: ':id/edit', element: <UploadRequestCreate /> }
            // { path: ':id', element: <UploadRequestDetails /> }
          ]
        },
        {
          path: 'upload-request',
          children: [
            { element: <Navigate to="/upload-request/list" replace /> },
            { path: 'list', element: <UploadRequestList /> },
            { path: 'list/requirement/:requirementId', element: <UploadRequestList /> },
            // { path: 'new', element: <UploadRequestCreate /> },
            // { path: ':id/edit', element: <UploadRequestCreate /> },
            { path: ':id', element: <UploadRequestDetails /> }
          ]
        },
        {
          path: 'suggest-improvement',
          element: <TypeformPage formId={'vumttCY1'} />
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/user/account/general" replace /> },
            { path: 'account/:tabValue', element: <UserAccount /> },
            { path: '*', element: <Navigate to="/user/account/general" replace /> }
          ]
        },
        {
          path: 'organization',
          children: [{ element: <OrganizationGeneralSettings /> }]
        },
        {
          path: 'subscriptions',

          children: [
            { element: <Subscriptions /> },
            { path: 'success', element: <SubscribeSuccess /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'upload/:urlLinkCode', element: <Upload /> },
        { path: 'upload/:urlLinkCode/success', element: <UploadComplete /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

const UserAccount = Loadable(lazy(() => import('../pages/dashboard/user/UserAccount')));
const OrganizationGeneralSettings = Loadable(
  lazy(() => import('../pages/dashboard/organization/OrganizationSettings'))
);
const Subscriptions = Loadable(lazy(() => import('../pages/Subscriptions/Subscriptions')));
const SubscribeSuccess = Loadable(lazy(() => import('../pages/Subscriptions/Success')));

const UploadRequirementTemplateList = Loadable(
  lazy(() => import('../pages/dashboard/upload-requirement/UploadRequirementTemplateList'))
);
const UploadRequirementTemplateCreate = Loadable(
  lazy(() => import('../pages/dashboard/upload-requirement/UploadRequirementTemplateCreate'))
);
const UploadRequestList = Loadable(
  lazy(() => import('../pages/dashboard/upload-request/UploadRequestList'))
);
const UploadRequestCreate = Loadable(
  lazy(() => import('../pages/dashboard/upload-request/UploadRequestCreate'))
);

const UploadRequestDetails = Loadable(
  lazy(() => import('../pages/dashboard/upload-request/UploadRequestDetails'))
);

// upload

const Upload = Loadable(lazy(() => import('../pages/upload/Upload')));
const UploadComplete = Loadable(lazy(() => import('../pages/upload/UploadComplete')));

//help
const TypeformPage = Loadable(lazy(() => import('../pages/help/TypeformPage')));

// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
